<template>
  <v-responsive class="j-select text-start">
    <general-text
      v-if="componentProps.label"
      :text="componentProps.label!"
      class="j-text--fs-12 mb-1"
    />
    <v-select
      open-text=""
      :items="items"
      hide-details="auto"
      :type="componentProps.type"
      :variant="componentProps.variant"
      :bg-color="bgColor"
      :rounded="componentProps.rounded"
      :density="componentProps.density"
      :prepend-inner-icon="componentProps.prependInnerIcon"
      :placeholder="componentProps.placeholder"
      :model-value="componentProps.modelValue"
      :error-messages="componentProps.errorMessages"
      :rules="(componentProps.rules as string[])"
      menu-icon="custom:selectIconActive"
      :item-color="itemColor"
      :item-title="itemTitle"
      :item-value="itemValue"
      :menu-props="{
        contentClass: 'j-select-menu',
        scrollStrategy: 'close',
      }"
      @update:model-value="updateModelValue"
      @click:append-inner="onAppendInnerClick"
    >
      <template
        v-if="$slots.selection"
        #selection="data"
      >
        <slot
          name="selection"
          v-bind="data"
        />
      </template>
      <template
        v-if="$slots.item"
        #item="data"
      >
        <slot
          name="item"
          v-bind="data"
        />
      </template>
    </v-select>
  </v-responsive>
</template>

<script setup lang="ts">
import type {
  Variant,
  Density,
} from '~/types/components/General/TextField';

const componentProps = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  label: {
    type: String,
    default: '',
  },
  variant: {
    type: String as () => Variant,
    default: 'solo' as Variant,
  },
  bgColor: {
    type: String,
    default: '',
  },
  itemColor: {
    type: String,
    default: '',
  },
  rounded: {
    type: String,
    default: '4',
  },
  density: {
    type: String as () => Density,
    default: 'compact' as Density,
  },
  modelValue: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: 'text',
  },
  prependInnerIcon: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  errorMessages: {
    type: String,
    default: '',
  },
  rules: {
    type: Array,
    default: () => [],
  },
  itemTitle: {
    type: String,
    default: 'title',
  },
  itemValue: {
    type: String,
    default: 'value',
  },
});
const emit = defineEmits([
  'update:modelValue',
  'click:append-inner',
]);
const scssVariables = useScssVariables();
const bgColor = computed(() => componentProps.bgColor || scssVariables.jColorInputBg);
const itemColor = computed(() => componentProps.itemColor || scssVariables.jTextRegular);
const updateModelValue = (modelValue: string) => emit('update:modelValue', modelValue);
const onAppendInnerClick = () => emit('click:append-inner');
</script>

<style scoped lang="scss">
.j-select {
  :deep(.v-input) {
    .v-field {
      border: 1px solid transparent;
      box-shadow: none;
    }
    .v-field__append-inner {
      color: $j-text-secondary;
    }
    .v-field__input {
      font-size: $j-form-control-font-size;
      line-height: normal;
      min-height: 38px;
    }
    .v-input__details {
      padding: 12px 0 0;
    }
    .v-select__menu-icon {
      opacity: 1;
      filter: brightness(10);
    }
  }
  :deep(.v-select--active-menu) {
    .v-select__menu-icon {
      filter: none;
    }
  }
  :deep(.v-input--error:not(.v-input--disabled)) {
    .v-field {
      border-color: $j-color-error
    }
    .v-field__input {
      color: $j-color-error;
    }
    .v-input__details {
      .v-messages {
        color: $j-color-error;
        .v-messages__message {
          line-height: 16px;
        }
      }
    }
  }
}
</style>
